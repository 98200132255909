$fontsFolderPath: '/assets/fonts';
@import '@squidit/css/src/scss/squid.scss';

* {
  transition: all ease 0.1s;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  scroll-behavior: smooth;
}

.custom-tab {
  background-color: var(--background_secondary) !important;
  display: none;

  li {
    border: solid 1px var(--primary_color) !important;
    color: var(--primary_color) !important;
    background-color: var(--background_secondary) !important;
    padding: 0.5rem 1rem !important;

    span {
      font-size: 1.2rem;
    }

    &.active {
      background-color: var(--primary_color) !important;
      span {
        color: var(--white-html) !important;
        font-weight: 400;
      }
    }
  }

  li:first-child {
    border-radius: 30px 0 0 30px !important;
  }

  li:last-child {
    border-radius: 0 30px 30px 0 !important;
  }
}

.custom-tab-hidden {
  ul {
    display: none;
  }
}

.mat-mdc-row:nth-child(even) {
  background-color: var(--background_secondary);

  &:hover {
    background-color: var(--background_secondary) !important;
  }
}

.mat-mdc-row:nth-child(odd) {
  background-color: var(--background);
}

.mat-mdc-header-cell, .mat-mdc-cell {
  color: var(--text_color);
  border-bottom: 1px solid var(--black_light);
}

.event-card {
  &.rejected-event-card {
    width: 50px;
  }

  &:hover {
    width: 100%;
    inset-inline-start: 0 !important;
  }
}

.dashboard-modal {

  .modal-content {
    background-color: var(--background);
  }
}
